document.addEventListener("DOMContentLoaded", () => {
  console.log("Document loaded.");
});

import FragmentShader from './render-engine/shaders/fragmentShader.js';
import VertexShader from './render-engine/shaders/vertexShader.js';
import { initShaderProgram } from './render-engine/init-shaders.js';
import { initBuffers } from './render-engine/init-buffers.js';
import { drawScene } from "./render-engine/draw-scene.js";
import { loadTexture } from "./render-engine/texture-loader.js";

let cameraRotation = 0.0;
let deltaTime = 0;

function main() 
{
  const canvas = document.querySelector("#glcanvas");

  const gl = canvas.getContext("webgl");

  if (gl === null) {
    alert(
      "Unable to initialize WebGL. Your browser or machine may not support it.",
    );
    return;
  }

  const shaderProgram = initShaderProgram(gl, VertexShader, FragmentShader);

  const programInfo = {
    program: shaderProgram,
    attribLocations: {
      vertexPosition: gl.getAttribLocation(shaderProgram, "aVertexPosition"),
      textureCoord: gl.getAttribLocation(shaderProgram, "aTextureCoord"),
    },
    uniformLocations: {
      projectionMatrix: gl.getUniformLocation(shaderProgram, "uProjectionMatrix"),
      modelViewMatrix: gl.getUniformLocation(shaderProgram, "uModelViewMatrix"),
      uSampler: gl.getUniformLocation(shaderProgram, "uSampler"),
    },
  };

  const buffers = initBuffers(gl);

  // Load texture
  const texture = loadTexture(gl, require("./render-engine/cubetexture.png"));
  
  // Flip image pixels into the bottom-to-top order that WebGL expects.
  gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, true);

  console.log(texture);

  let then = 0;

  function render(now) {
    now *= 0.001; // convert to seconds
    deltaTime = now - then;
    then = now;

    drawScene(gl, programInfo, buffers, texture, cameraRotation);
    cameraRotation += deltaTime;

    requestAnimationFrame(render);
  }
  requestAnimationFrame(render);
}


main();
