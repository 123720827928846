export default `
precision mediump float;
     
// Passed in from the vertex shader.
varying vec2 vTextureCoord;
 
// The texture.
uniform sampler2D uSampler;
 
void main() {
   gl_FragColor = texture2D(uSampler, vTextureCoord);
}
`;
