export default `
attribute vec4 aVertexPosition;
attribute vec2 aTextureCoord;
 
uniform mat4 uModelViewMatrix;
uniform mat4 uProjectionMatrix;

varying vec2 vTextureCoord;
 
void main() {
  // Multiply the position by the matrix.
  gl_Position = uProjectionMatrix * uModelViewMatrix * aVertexPosition;
 
  // Pass the texcoord to the fragment shader.
  vTextureCoord = aTextureCoord;
}
`;
